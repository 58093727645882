import Vue from 'vue'
import App from './App.vue'
import VueModal from '@kouts/vue-modal'
import '@kouts/vue-modal/dist/vue-modal.css'

// import axios from 'axios'
// import cors from 'cors'

const newLocal = 'Modal'
Vue.component(newLocal, VueModal)

// axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers['Access-Control-Allow-Headers'] = "GET,HEAD,OPTIONS,POST,PUT";
// axios.defaults.headers['Access-Control-Allow-Methods'] = "Origin, X-Requested-With, Content-Type, Accept, Authorization";
// axios.defaults.withCredentials = true

// Vue.use(cors)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  components: {
    'Modal': VueModal
  },
}).$mount('#app')
