<template>
  <div id="app" style="margin-top:20px">
    <div
      align="left"
      class="my-1 mx-2"
    >
      <h3>ECC PROJECT
      <br>
      <span style="font-weight:lighter;">PRIVATE SERVICE</span></h3>
    </div>

    <div
      class="thin_line"
      style="margin-top:30px;"
    ></div>
    <!-- <div
      class="my-1 index_title"
      style=""
    >
    Usage information
    </div>
    <div
      class="thin_line"
    ></div> -->

    <!-- /////////// INDEX ////////// -->
    <div
      v-if="mainShow"
    >
      <div class="welcomeText" style="margin-top:45px;">
        <p>ECC Project의 </p>
        <p>비전과 미래에 함께 동참해주셔서</p>
        <p>진심을 담아 감사 드립니다.</p>

        <p>항상 귀를 기울이는 자세로 </p>
        <p>마음을 읽고 경험을 같이 나누어 </p>
        <p>앞으로 나아가겠습니다.</p>
        <div style="height:20px;"></div>
        <p>무궁한 발전을 기원하며,</p>
        <p>저희와 소중한 인연이</p>
        <p>계속되길 희망합니다.</p>
      </div>
      <div style="padding:45px 0;">
        <button class="btn btn-public mr-2" @click="mainShow=false;publicShow=true;">
          public key - 공개 키
        </button>

        <button class="btn btn-private ml-2" @click="mainShow=false;privateShow=true;">
          private key - 개인 키
        </button>
      </div>
    </div>
    <!-- /////////// INDEX ////////// -->

    <!-- /////////// PUBLIC ////////// -->
    <div
      v-else-if="publicShow"
    >
        
      <pub-content />

      <div style="padding:30px 0;">
        <button class="btn btn-main mr-2" @click="publicShow=false;mainShow=true;">
          처음으로
        </button>
        <button class="btn btn-private ml-2" @click="publicShow=false;privateShow=true;">
          private key - 개인 키
        </button>
      </div>
    </div>
    <!-- /////////// PUBLIC ////////// -->


    
    <!-- /////////// PRIVATE ////////// -->
    <div
      v-else-if="privateShow"
    >

      <priv-content />

      <div style="padding:30px 0;">
        <button class="btn btn-main mr-2" @click="privateShow=false;mainShow=true;">
          처음으로
        </button>

        <button class="btn btn-public ml-2" @click="privateShow=false;publicShow=true;">
          public key - 공개 키
        </button>
      </div>
    </div>
    <!-- /////////// PRIVATE ////////// -->


  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'App',
  components: {
    PubContent: () => import('@/components/PubCon.vue'),
    PrivContent: () => import('@/components/PrivCon.vue'),
  },
  data () {
    return {
      showModal: false,
      walletAddress: '',
      innerData: '',
      showDiv: false,
      publicShow: false,
      privateShow: false,
      mainShow:true,
    }
  },
  methods: {
    getData(){
      // var sendData = {
      //   balanceOf_account: this.walletAddress,
      // }
      // var header = {
      //   "Access-Control-Allow-Origin" : "*",
      //   "Content-type" : "application/json",
      // }
      // axios.post(process.env.VUE_APP_API_URL,sendData,header)
      axios.get("https://api.polygonscan.com/api?module=account&action=tokenbalance&contractaddress=0x7d85f6896841e7d9335505cd8a355e5f9f4978ad&tag=latest&apikey=RU9PYFU6YHM23B6TXP2K7GGPZQFUMI2F3H&address=" + this.walletAddress)
      // axios.get(process.env.VUE_APP_API_URL + "&address=" + this.walletAddress)
        .then(res => {
          var data = res.data.result
          if(parseInt(data) && data > (10 * (10**18))) {
            data = data.slice(0, -18)
            data = this.AddComma(data)
          }else{
            data = "Error"
          }
          // this.insertData(data.balanceOf_output)
          this.insertData(data)
        })
    },
    AddComma(num){
      var regexp = /\B(?=(\d{3})+(?!\d))/g
      return num.toString().replace(regexp, ',')
    },
    insertData(val) {
      // console.log(val)
      this.showDiv = true
      this.innerData = val + ' ECC'
    },
    initForm(){
      this.showDiv = false
    },
  },
}
</script>

<style>

@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');
/* @import url('http://fonts.cdnfonts.com/css/cormorant-sc'); */
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');
/* <link href='https://fonts.googleapis.com/css?family=Playfair Display' rel='stylesheet'> */
@import url(//fonts.googleapis.com/earlyaccess/nanummyeongjo.css);
.v-application {
  /* font-family: 'Cormorant SC', sans-serif; */
  font-family: Pretendard, -apple-system, 'Apple SD Gothic Neo', sans-serif !important;
}
#app {
  top: auto;
  margin: auto;
  height:90vh;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  max-width: 600px;
  word-break: keep-all;
}
.welcomeText{
  font-family: 'Nanum Myeongjo', serif !important;
  font-size:18px;
  line-height: 27px;
}
.btn {
  background-color:#1a9312;
  color:#fff;
}
.modal-wrapper {
  display: flex;
  align-items: center;
}
.modal-wrapper .vm {
  top: auto;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.index_title_bak {
  background: linear-gradient(50deg, #b8b4b4, #6c6564, #999190);
  /* background-color:#6c6564; */
  padding:5px;
  color:azure;
  font-weight:100;
  letter-spacing:2px;
}

.index_title {
  /* font-family: 'Playfair Display', sans-serif; */
  font-family: Pretendard, -apple-system, 'Apple SD Gothic Neo', sans-serif !important;
  /* font-size:1.5rem; */
  font-size:10pt;
  padding:0px;
  color:rgb(20, 20, 20);
  font-weight:600;
  letter-spacing:4px;
}
.thin_line {
  border-bottom: 0;
  padding:0px;
  height:1px;
  /* border: 1px black solid; */
  /* border-color:rgb(39, 37, 37); */
  /* background: linear-gradient(to bottom, transparent 80%, currentColor 20%); */
  /* background: linear-gradient(to bottom, transparent 80%, currentColor 20%); */
  border:0;
  box-shadow: 0 0.4px;
}

.btn-main {
  border-top:1px #3f3f3f solid;
  border-bottom:1px #3f3f3f solid;
  background-color: #fefefe;
  color:#3f3f3f;
}
.btn-public {
  border-top:1px #5f5f5f solid;
  border-bottom:1px #5f5f5f solid;
  background-color: #fefefe;
  color:#5f5f5f;
}
.btn-public:hover {
  color:#5f5f5f;
}
/* .btn-public {
  background-color: #5f5f5f;
  color:#fff;
} */

.btn-private {
  border-top:1px #b32806 solid;
  border-bottom:1px #b32806 solid;
  background-color: #fefefe;
  color:#b32806;
}

.btn-private:hover {
  color:#b32806;
}
</style>
